<template>
    <div class="login-container">
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left">

            <div class="title-container">
                <h3 class="title">FUSION 动感单车房</h3>
            </div>

            <el-form-item prop="username">
                <el-input
                        ref="username"
                        v-model="loginForm.username"
                        placeholder="Username"
                        name="username"
                        type="text"
                        tabindex="1"
                        auto-complete="on"
                />
            </el-form-item>

            <el-form-item prop="password">
                <el-input
                        :key="passwordType"
                        ref="password"
                        v-model="loginForm.password"
                        :type="passwordType"
                        placeholder="Password"
                        name="password"
                        tabindex="2"
                        auto-complete="on"

                />
            </el-form-item>

            <el-button :loading="loading" type="primary" style="width:100%;margin-bottom:30px;" @click="handleLogin">登录</el-button>



        </el-form>
    </div>
</template>

<script>
    // import { validUsername } from '@/utils/validate'

    export default {
        name: 'Login',
        data() {
            // const validateUsername = (rule, value, callback) => {
            //     if (!value.length<4) {
            //         callback(new Error('Please enter the correct user name'))
            //     } else {
            //         callback()
            //     }
            // }
            // const validatePassword = (rule, value, callback) => {
            //     if (value.length < 6) {
            //         callback(new Error('The password can not be less than 6 digits'))
            //     } else {
            //         callback()
            //     }
            // }
            return {
                loginForm: {
                    // username: 'admin',
                    // password: '123456'
                    username: '',
                    password: ''
                },
                loginRules: {
                    username: [{ required: true, trigger: 'blur', }],
                    password: [{ required: true, trigger: 'blur', }]
                },
                loading: false,
                passwordType: 'password',
                redirect: undefined
            }
        },
        watch: {
            $route: {
                handler: function(route) {
                    this.redirect = route.query && route.query.redirect
                },
                immediate: true
            }
        },
        methods: {
            showPwd() {
                if (this.passwordType === 'password') {
                    this.passwordType = ''
                } else {
                    this.passwordType = 'password'
                }
                this.$nextTick(() => {
                    this.$refs.password.focus()
                })
            },
            handleLogin() {
                this.$refs.loginForm.validate(valid => {
                    console.log('valid:',valid)
                    if (valid) {
                        this.loading = true
                        this.$store.dispatch('user/login', this.loginForm).then(() => {
                            this.$router.push({ path: this.redirect || '/' })
                            this.loading = false
                        }).catch(() => {
                            this.loading = false
                        })
                    } else {
                        console.log('error submit!!')
                        return false
                    }
                })
            }
        }
    }
</script>
<style scoped>


    /*$bg:#283443;*/
    /*$light_gray:#fff;*/
    /*$cursor: #fff;*/

    @supports (-webkit-mask: none) and (not (cater-color: #fff)) {
        .login-container .el-input input {
            color: #fff;
        }
    }

    /* reset element-ui css */
    /*.login-container {*/
    .login-container .el-input {
        display: inline-block;
        height: 47px;
        /*width: 85%;*/
    }
    .login-container input {
        background: transparent;
        border: 0px;
        -webkit-appearance: none;
        border-radius: 0px;
        padding: 12px 5px 12px 15px;
        color: #fff;
        height: 47px;
        caret-color: #fff;
    }
    .login-container:-webkit-autofill {
         box-shadow: 0 0 0px 1000px #283443 inset !important;
         -webkit-text-fill-color: #fff !important;
     }

    .el-form-item {
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        color: #454545;
    }



    /*$bg:#2d3a4b;*/
    /*$dark_gray:#889aa4;*/
    /*$light_gray:#eee;*/

    .login-container {
        min-height: 100%;
        width: 100%;
        background-color: #2d3a4b;
        overflow: hidden;
    }
    .login-container .login-form {
        position: relative;
        width: 520px;
        max-width: 100%;
        padding: 160px 35px 0;
        margin: 0 auto;
        overflow: hidden;
    }

    .tips {
        font-size: 14px;
        color: #fff;
        margin-bottom: 10px;
    }

    .tips span:first-of-type {
         margin-right: 16px;
     }


    .svg-container {
        padding: 6px 5px 6px 15px;
        color: #889aa4;
        vertical-align: middle;
        width: 30px;
        display: inline-block;
    }

    .title-container {
        position: relative;
    }
    .title-container  .title {
        font-size: 26px;
        color: #eee;
        margin: 0px auto 40px auto;
        text-align: center;
        font-weight: bold;
    }


    .show-pwd {
        position: absolute;
        right: 10px;
        top: 7px;
        font-size: 16px;
        color: #889aa4;
        cursor: pointer;
        user-select: none;
    }

</style>
